import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Tout = makeShortcode("Tout");
const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Migration from .md to (.mdx) files`}</h1>
    <h3>{`Setting up Local Development`}</h3>
    <p>{`Let's set up local development environments! Being able to hotload your changes into a local website is no small feat and is a useful skill. `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/tutorial/"
      }}>{`Here is Gatsby's guide to doing basically everything in this doc`}</a>{` it's elegant, thorough, and extraordinarily helpful. Plus it's like a half-hour, tops, of following directions and installing things.`}</p>
    <h3>{`The Terminal`}</h3>
    <p>{`🤨 `}<strong parentName="p">{` I see you're reading on... `}</strong></p>
    <p>{`If one hasn't done anything before in the terminal, recognize that it's the pinnacle of laziness & elegance. Developers literally have set this thing up to run on as few characters as possible. Our devs, specifically, have set community contributors to use even fewer commands.`}</p>
    <p>{`If you're lazy (congratulations 🎊 ) then here the short-short guide to installs and spinning up your local development environment:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://code.visualstudio.com/download"
        }}>{`VS code`}</a>
        <ul parentName="li">
          <li parentName="ul">{`IDE's with the best text highlighting experience, and development ease/packages/extensions/themes, borders on an obsession. so, download packages/extensions at one's discretion. Generally, it's best to have:`}
            <ul parentName="li">
              <li parentName="ul">{`A linter`}</li>
              <li parentName="ul">{`A prettier extension`}</li>
              <li parentName="ul">{`Something to enable terminal(the shell) in your IDE`}</li>
              <li parentName="ul">{`A theme <-- this is where the obsessions start`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://brew.sh/"
        }}>{`homebrew`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Little package manager that makes life so much easier. Also, it allows package installs for everything this portal needs.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://nodejs.org/en/"
        }}>{`node.js`}</a>
        <ul parentName="li">
          <li parentName="ul">{`the underlying architecture under the hood is react.js. Both Nodejs and React are javascript languages that can be executed both client and server-side. Developers can execute the Reactjs code directly in the Nodejs environment. The React DOM has components specifically designed to work with Nodejs that reduce lines of code, making server-side rendering comparatively easy.`}</li>
        </ul>
      </li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`brew install node
`}</code></pre>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://yarnpkg.com/"
        }}>{`yarn`}</a>
        <ul parentName="li">
          <li parentName="ul">{`A simpler package manager with some fancy sauce on it in the comm-dev instance. Let's do command line for yarn too 🤭`}</li>
        </ul>
      </li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`brew install yarn
 // or
 npm install -g yarn
`}</code></pre>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": ""
        }}>{`Gatsby`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Let's install gatsby via terminal, keeping those terminal command skills sharp.`}</li>
        </ul>
      </li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm install -g gatsby-cli
`}</code></pre>
    <Tout mdxType="Tout">
      <Box mdxType="Box">
        <h2>{`On a PC?`}</h2>
        <p>{`Homebrew isn't necessary on PC, since the command prompt in windows allows you to interact with the same stack. You'll only need node, yarn, and gatsby. Repeating the intro: the Gatsby `}<a parentName="p" {...{
            "href": "https://www.gatsbyjs.org/tutorial/"
          }}>{`guide to installs`}</a>{` covers other platform setups.`}</p>
        <p><a parentName="p" {...{
            "href": "https://www.gatsbyjs.com/tutorial/part-zero/#windows-instructions"
          }}>{`Windows instructions`}</a></p>
        <p><a parentName="p" {...{
            "href": "https://www.gatsbyjs.com/tutorial/part-zero/#linux-instructions"
          }}>{`Linux instructions`}</a></p>
      </Box>
    </Tout>
    <h3>{`I Pressed Buttons, Now What??`}</h3>
    <p>{`By this point all the dependencies should be downloaded. The next step is to pull the portal and build some pages up locally.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/makerdao/community-portal"
      }}>{`clone that community repo`}</a>{` and get building in the IDE! Getting started is as simple as entering the commands `}<inlineCode parentName="p">{`yarn`}</inlineCode>{` then `}<inlineCode parentName="p">{`yarn start`}</inlineCode>{` in the terminal. So head to terminal and run those commands in order. Normally, there is a flurry of activity in the terminal and eventually some pages that say success.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/EL88h6k.png",
        "alt": null
      }}></img></p>
    <p>{`Navigate to localhost:8000 in the web browser of choice. 💥 Boom, you just built your local environment. Now when editing an .mdx file and hitting save, Gatsby will do all the work to render the site for you with all the new `}<strong parentName="p"><em parentName="strong">{`glorious`}</em></strong>{` changes.`}</p>
    <h3>{`Common Commands to Fix Things`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`yarn`}</inlineCode>{` before you `}<inlineCode parentName="li">{`yarn start`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`yarn clean`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`gatsby clean`}</inlineCode></li>
      <li parentName="ul">{`Possibly you'll need to delete the packagelock.json file. then run those clean commands again.`}</li>
      <li parentName="ul">{`Occasionally closing VScode and the browser will fix things, in addition to the commands above.`}</li>
      <li parentName="ul">{`Opening multiple terminal windows and running `}<inlineCode parentName="li">{`yarn start`}</inlineCode>{` will make the browser throw errors; it's trying to send two builds to one server. Close one of the terminals and try it all again.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      